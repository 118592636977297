const getOverlayStyles = (theme) => ({
    byDisplayState: {
        default: {
            color: theme.customColors.portfolio.onHover,
            backgroundColor: theme.customColors.portfolio.hover,
            outline: `2px solid ${theme.customColors.portfolio.hover}`,
        },
        error: {
            color: theme.customColors.portfolio.onHover,
            backgroundColor: theme.customColors.portfolio.hover,
            outline: `2px solid ${theme.customColors.portfolio.hover}`,
        },
        archive: {
            color: theme.customColors.portfolio.onHover,
            backgroundColor: theme.palette.text.secondary,
            outline: `2px solid ${theme.palette.text.secondary}`,
        },
    },
});
export default getOverlayStyles;
